import React from 'react';
import loadable from '@loadable/component';
import classnames from 'classnames';

import { RAISE_BUG_GH } from 'constants/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faTicketAlt, faEnvelopeSquare } from '@fortawesome/pro-light-svg-icons';
import { features } from 'gatsby-env-variables';

import useStyles from './styles';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const Typography = loadable(() => import('@material-ui/core/Typography'));

const LazyNavCta = loadable(() => import('components/cta/navigiate'));
const LazyReportBugIconList = loadable(() => import('components/icon-list/report-bug'));

const LandingHeadline = () => {
  const styles = useStyles();

  const { showGitHubSupport = false } = features;

  return (
    <section>
    <Grid spacing={1}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
     >
      <Grid item xs={12} md={10} lg={8} xl={6}>
        <Paper className={styles.paper} elevation={4}>
          <Grid spacing={1}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
           >
           <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h3"
                color="inherit"
                align="center"
                className={classnames(styles.headerBlue, styles.fourTopBotMargin)}>
                  {'Contact technical support'}
                  <FontAwesomeIcon icon={faBug} size="lg" className={styles.headerIcon}/>
                <Typography
                  variant="caption"
                  component="div"
                  align="right"
                  className={styles.headerSubText}>
                    Please get in touch with any issues, we are here to help
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" component="p" align="justify" className={styles.body}>
                {`We are always working to improve Total Loyalty, it is our goal to provide the best Loyalty solution
                  available.  The Total Loyalty Development team are always looking for problems or things that don't quite work
                  as our customer would expect. As a valued customer and a user of our software we appreciate you probably know
                  more than we do and we would appreciate being informed of any problems you have with our software.`}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h5"
                align="center"
                className={styles.headerGreen}>
                How to ask for technical support
              </Typography>
            </Grid>

              {showGitHubSupport ? (
                <Grid item xs={12}>
                  <div className={styles.twoToFourBottomMargin}>
                    <LazyReportBugIconList />
                  </div>
                </Grid>
              ) : (
                <>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" component="p" align="justify" className={styles.body}>
                    {`Please use the button below to send our team an email. Please include as much information as possible, including
                      a screen shot of any problems if possible. One of our team be in touch as soon as possible to help.`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.navCta}>
                    <LazyNavCta
                      icon={faEnvelopeSquare}
                      externalRoute
                      route={'mailto:darren@total-loyalty.com?subject=Total Loyalty Support Request'}
                      primary="Email our team for support"
                      primarySmall="Email Support"
                      secondary="Email our team direct with any questions or problems"
                      />
                  </div>
                </Grid>
                </>
              )}

            {showGitHubSupport && (
              <Grid item xs={12}>
                <div className={styles.navCta}>
                  <LazyNavCta
                    icon={faTicketAlt}
                    externalRoute
                    route={RAISE_BUG_GH}
                    primary="Create a support a new issue"
                    primarySmall="Create support issue"
                    secondary="Raise a support issue or update existing issue"
                    />
                </div>
              </Grid>
            )}

          </Grid>
        </Paper>
      </Grid>
    </Grid>
    </section>
  );
};

export default LandingHeadline;
